.narrow {
  margin: 0 auto;
}

.page-padding {
  padding: 4rem;
}

.welcome.page>.narrow>img {
  margin: 0 auto;
  display: block;
  width: 200px;
}

.welcome.page>.narrow ul {
  width: 100%;
  justify-content: space-between;
  margin: 4rem auto;
  border-bottom: none;
  margin-top: 20px;
}

.welcome.page>.narrow ul>li {
  background-color: inherit;
  margin: auto;
}

.welcome.page>.narrow ul>li>a {
  font-size: 14px;
  min-height: 32px;
  border-bottom-color: rgb(98, 100, 167);
}

.center {
  text-align: center;
}

.sections>* {
  margin: 4rem auto;
}

pre,
div.error {
  background-color: #e5e5e5;
  padding: 1rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin: 1rem 0;
  max-height: 200px;
  overflow-x: scroll;
  overflow-y: scroll;
}

pre.fixed,
div.error.fixed {
  height: 200px;
}

code {
  background-color: #e5e5e5;
  display: inline-block;
  padding: 0px 6px;
  border-radius: 3px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.error {
  color: red;
}

.main-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.main-section>.deeplink-functions-container {
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.main-section>.menu-container {
  width: 60%;
  padding: 10px;
}